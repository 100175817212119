import BalloonAds from "./balloon-ads";

$(() => {
  if ($(".js-nav").length <= 0) return;

  const $win = $(window);
  const $nav = $(".js-nav");
  const $overlay = $(".js-nav[data-overlay-ad='true']");
  let startPosition = 0;
  if ($(".js-nav[data-navigation]").length > 0) {
    const navHeight = $nav.outerHeight() - 10;
    $win.on("load scroll", function () {
      const value = $(this).scrollTop();
      if (value > startPosition && value > navHeight) {
        $nav.css("top", -1);
      } else {
        const headerHeight = $(".js-header").outerHeight() - 1;
        $nav.css("top", +headerHeight);
      }
      if (startPosition > 400) {
        $nav.addClass("is-active");
      } else {
        $nav.removeClass("is-active").css("top", 0);
      }
      startPosition = value;
    });
  } else if ($(".js-nav[data-navigation-bottom]").length > 0) {
    // MEMO: ホテルLPの場合はポジションは固定
    if ($("#js-hotels-theme").length > 0) return;
    const navHeight = $nav.outerHeight();
    $win.on("load scroll", function () {
      const value = $(this).scrollTop();
      if (value > startPosition && value >= 320) {
        $nav.css("bottom", navHeight + 16 + "px");
      } else if (value < startPosition) {
        $nav.css("bottom", 12 + "px");
      }
      startPosition = value;
    });
  } else if ($overlay.length > 0) {
    const navHeight = $overlay.outerHeight();
    $win.on("load scroll", function () {
      const value = $(this).scrollTop();
      if (value > startPosition && value >= 320) {
        $overlay.css("padding-bottom", 0 + "px");
        $overlay.css("bottom", -navHeight + "px");
      } else if (value < startPosition) {
        $overlay.css("padding-bottom", navHeight + "px");
        $overlay.css("bottom", 0 + "px");
      }
      startPosition = value;
    });
  }

  const balloonAds = BalloonAds();
  if (balloonAds) {
    const options = { rootMargin: "-100% 0%" };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        balloonAds.toggle(!entry.isIntersecting);
      });
    }, options);
    observer.observe(document.querySelector(".js-floating-viewport"));
  }
});
