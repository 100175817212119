import Cookies from "js-cookie";

export default () => {
  const COOKIE_KEY = "hide_join_header";
  const joinHeader = document.getElementById("js-join-header");

  if (Cookies.get(COOKIE_KEY) || !joinHeader) {
    return;
  }

  const closeButton = document.getElementById("js-join-header-close");
  closeButton.addEventListener("touchstart", (event) => {
    Cookies.set(COOKIE_KEY, "1", { expires: 7 });
    joinHeader.remove();
    event.preventDefault();
  });
};
