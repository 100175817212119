import Cookies from "js-cookie";

export default () => {
  const COOKIE_KEY = "hide_balloon_ads";

  if (Cookies.get(COOKIE_KEY) || !document.getElementById("js-balloon-ads")) {
    return;
  }

  const content = document.getElementById("js-balloon-ads");
  const closeButton = document.getElementById("js-balloon-ads-close");
  const show = () => {
    content.classList.add("is-show");
  };
  const hide = () => {
    content.classList.remove("is-show");
  };
  closeButton.addEventListener("touchstart", (event) => {
    // eslint-disable-next-line no-undef
    onclickcatalyst_floating_ad("footer_promotion_close");
    Cookies.set(COOKIE_KEY, "1", { expires: 3 });
    content.remove();
    event.preventDefault();
  });

  return {
    show: show,
    hide: hide,
    toggle: (display) => {
      if (display) {
        show();
      } else {
        hide();
      }
    },
  };
};
