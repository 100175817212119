import Cookies from "js-cookie";

export default () => {
  if (Cookies.get("hide_balloon") || !document.getElementById("js-balloon")) {
    return;
  }

  const content = document.getElementById("js-balloon");
  const closeButton = document.getElementById("js-balloon-close");
  const show = () => {
    Cookies.set("hide_balloon", "1", { expires: 7 });
    content.classList.add("is-show");
  };
  const hide = () => {
    content.classList.remove("is-show");
  };
  closeButton.addEventListener("touchstart", () => {
    content.remove();
  });

  return {
    show: show,
    hide: hide,
    toggle: (display) => {
      if (display) {
        show();
      } else {
        hide();
      }
    },
  };
};
