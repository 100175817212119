import lozad from "lozad";
import { bindMicromodalEventTrigger } from "javascripts/micromodal";
import { insertAbout } from "javascripts/webpage/insert-about";
import { swipeForWindows } from "javascripts/webpage/swipe-for-windows";
import { smoothScroll } from "javascripts/webpage/smooth-scroll";
import { headerScroll } from "javascripts/webpage/sp/header-scroll";

document.addEventListener("DOMContentLoaded", () => {
  function pollHotels() {
    const loader = document.querySelector(".js-loader");
    fetch(document.querySelector(".js-polling-url").dataset.pollingUrl)
      .then((response) => {
        if (!response.ok) {
          throw "ErrorException";
        }
        return response.json();
      })
      .then((data) => {
        if (data.html) {
          loader.style.display = "none";
          searchRoomPlans.innerHTML = data.html;
          const addedElements = searchRoomPlans.querySelectorAll(".js-lazy:not([data-loaded])");
          const newObserver = lozad(addedElements, {
            rootMargin: "200% 400%",
          });
          newObserver.observe();
          bindMicromodalEventTrigger(searchRoomPlans);
          document.querySelectorAll(".js-reserve-button").forEach((button) => {
            button.addEventListener("click", () => {
              const bedOptions = document.querySelector(".js-bed-options");
              bedOptions.innerHTML = "";
              document.querySelector(".js-bed-reserve-button").classList.add("c-button__link--disabled");
              JSON.parse(button.dataset.beds).forEach((bed) => {
                bedOptions.innerHTML += `
                  <li class="p-hotels-shared-radio__item">
                    <input class="p-hotels-shared-radio__radio js-bed-option" type="radio" value="${bed.plan_id}" name="plan_id" id="${bed.plan_id}">
                    <label class="p-hotels-shared-radio__label" for="${bed.plan_id}">${bed.description}</label>
                  </li>
                `;
              });
              bedOptions.querySelectorAll(".js-bed-option").forEach((option) => {
                option.addEventListener("click", () => {
                  document.querySelector(".js-bed-reserve-button").classList.remove("c-button__link--disabled");
                });
              });
            });
          });
          swipeForWindows();
          smoothScroll();
          insertAbout();
          headerScroll();
        } else {
          setTimeout(pollHotels, 1000);
        }
      })
      .catch(() => {
        loader.style.display = "none";
        const errorMessage = `
          <div class="c-no-results">
            <h3 class="c-no-results__heading">サーバーエラー</h3>
            <p class="c-no-results__description">
              ただいまサイトへのアクセスが大変混み合っております。
              <br>
              しばらくたってから再度ご利用ください。
            </p>
          </div>`;
        document.querySelector(".js-search-room-plans").innerHTML = errorMessage;
      });
  }
  const searchRoomPlans = document.querySelector(".js-search-room-plans");
  if (searchRoomPlans) {
    searchRoomPlans.innerHTML += '<div class="p-hotels-shared-loader js-loader">プランを取得中です</div>';

    pollHotels();
  }
});
