import Balloon from "./balloon";

document.addEventListener("DOMContentLoaded", function () {
  if (!document.querySelector("#js-pages.pages-top")) {
    return;
  }

  const balloon = Balloon();
  if (balloon) {
    const options = { rootMargin: "0px 0px 300%" };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        balloon.toggle(!entry.isIntersecting);
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
        }
      });
    }, options);
    observer.observe(document.querySelector(".l-footer"));
  }
});
