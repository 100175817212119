// NOTE: PWA(iOS)のみ表示するメッセージ
document.addEventListener("DOMContentLoaded", function () {
  const pwa = window.matchMedia("(display-mode: standalone)").matches;
  const userAgent = navigator.userAgent.toLowerCase().indexOf("iphone");
  const param = "?lid=homescreen";
  const notice = document.querySelector(".js-pwa-notice");
  const noticeSection = document.getElementById("target-pwa-notice");

  if (!notice) {
    return;
  }
  if (pwa && userAgent && userAgent > 0 && location.search == param) {
    notice.style.visibility = "visible";
  } else {
    notice.remove();
    noticeSection.remove();
  }
});
