document.addEventListener("DOMContentLoaded", () => {
  const searchForm = document.querySelector(".js-search-form");
  let searchButton, refineForm, sortForm, destinationInputs, searchTagTitle, searchTagInput;
  if (searchForm) {
    searchButton = searchForm.querySelector(".js-search-button");
    refineForm = document.querySelector(".js-refine-form");
    sortForm = document.querySelector(".js-sort-form");
    [searchForm, refineForm, sortForm].forEach((form) => {
      if (form) {
        form.addEventListener("submit", (e) => {
          e.preventDefault();

          search(form.action, form.dataset.combineForms);
        });
      }
    });

    destinationInputs = Array.from(searchForm.querySelectorAll(".js-search-destination-input"));
    searchTagTitle = document.querySelector(".js-search-tag-title");
    searchTagInput = searchForm.querySelector(".js-search-tag-input");
    const checkInputObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type == "attributes" && mutation.attributeName == "value") {
          checkInputs();
        }
      });
    });
    destinationInputs.forEach((input) => {
      checkInputObserver.observe(input, { attributes: true });
    });
    Array.from(searchForm.querySelectorAll(".js-search-required-input")).forEach((input) => {
      checkInputObserver.observe(input, { attributes: true });
    });
    checkInputObserver.observe(searchTagInput, { attributes: true });

    document.querySelectorAll(".js-search-tag").forEach((element) => {
      element.addEventListener("click", () => {
        if (window.confirm("検索条件を削除しても良いですか？")) {
          searchTagTitle.innerHTML = "";
          searchTagInput.setAttribute("value", "");
          searchTagInput.setAttribute("name", "");
          searchTagInput.dataset.searchTagPresented = false;
          element.classList.add("u-display__none");
          searchForm.setAttribute("action", "/hotellist/result");
          document.querySelectorAll(".js-search-destination-input").forEach((destinationInput) => {
            destinationInput.removeAttribute("disabled");
          });
          document.querySelector(".js-destination-text").classList.remove("u-display__none");
        }
      });
    });

    document.querySelectorAll(".js-search-tag-target").forEach((element) => {
      element.addEventListener("click", () => {
        document.querySelectorAll(".js-search-destination-input").forEach((destinationInput) => {
          destinationInput.setAttribute("disabled", "disabled");
        });
        document.querySelector(".js-destination-text").classList.add("u-display__none");
        document.querySelector(".js-search-tag").classList.remove("u-display__none");
        searchTagTitle.innerHTML = element.dataset.title;
        searchTagInput.dataset.searchTagPresented = true;
        searchTagInput.setAttribute("value", element.dataset.value);
        searchTagInput.setAttribute("name", element.dataset.key);
        if (element.dataset.key == "hotel_id") {
          searchTagInput.setAttribute("value", "");
          searchTagInput.setAttribute("name", "");
          searchForm.setAttribute("action", element.dataset.aggregated === "true" ? `/hotels-${element.dataset.value}/plans` : `/hotels/${element.dataset.value}/plans`);
        } else {
          searchTagInput.setAttribute("value", element.dataset.value);
          searchTagInput.setAttribute("name", element.dataset.key);
          searchForm.setAttribute("action", "/hotellist/result");
        }
      });
    });
    checkInputs();
  }

  function search(action, combineForms) {
    const combineFormsArray = combineForms.split(",");
    let searchParamsArray = [];
    if (combineFormsArray.includes("search")) searchParamsArray.push(searchParams());
    if (combineFormsArray.includes("refine")) searchParamsArray.push(refineParams());
    if (combineFormsArray.includes("sort")) searchParamsArray.push(sortParams());
    window.location.href = `${action}?${searchParamsArray.filter(Boolean).join("&")}`;
  }

  function isFormDataPresent(formData) {
    for (let item of formData) {
      return true;
    }
    return false;
  }

  function searchParams() {
    const formData = new FormData(searchForm);
    formData.delete(searchForm.querySelector(".js-child").name);
    formData.append("children", formData.getAll("children[]").join(","));
    formData.delete("children[]");
    if (isFormDataPresent(formData)) {
      return new URLSearchParams(formData).toString();
    } else {
      return "";
    }
  }

  function refineParams() {
    if (refineForm) {
      const formData = new FormData(refineForm);
      const planConditions = [];
      const hotelTypes = [];
      const hotelConditions = [];

      if (document.querySelector(".js-plan-conditions")) {
        document.querySelectorAll("input.js-plan-conditions:checked").forEach((input) => {
          planConditions.push(input.value);
        });
        document.querySelectorAll("select.js-plan-conditions").forEach((select) => {
          if (select.value.length > 0) {
            planConditions.push(select.value);
          }
        });
        formData.delete("plan_conditions[]");
        formData.append("plan_conditions", planConditions.join(","));
      }
      if (document.querySelector(".js-hotel-conditions")) {
        document.querySelectorAll("input.js-hotel-conditions:checked").forEach((input) => {
          hotelConditions.push(input.value);
        });
        document.querySelectorAll("select.js-hotel-conditions").forEach((select) => {
          hotelConditions.push(select.value);
        });
        formData.delete("hotel_conditions[]");
        formData.append("hotel_conditions", hotelConditions.join(","));
      }
      if (document.querySelector("input.js-hotel-types")) {
        document.querySelectorAll("input.js-hotel-types:checked").forEach((input) => {
          hotelTypes.push(input.value);
        });
        formData.delete("hotel_types[]");
        formData.append("hotel_types", hotelTypes.join(","));
      }

      if (isFormDataPresent(formData)) {
        return new URLSearchParams(formData).toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  function sortParams() {
    if (sortForm) {
      const formData = new FormData(sortForm);
      if (isFormDataPresent(formData)) {
        return new URLSearchParams(formData).toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  function checkInputs() {
    if (searchForm) {
      const destinationFilled = destinationInputs.length === 0 || destinationInputs.some((input) => input.value.trim() !== "");
      const searchTagPresented = searchTagInput.dataset.searchTagPresented;
      const isHotelPlansSearch = searchTagInput.dataset.hotelPlansSearch;
      const requiredFilled = Array.from(searchForm.querySelectorAll(".js-search-required-input:not([disabled])")).every((input) => input.value.trim() !== "");
      const adult = searchForm.querySelector(".js-adult").value;
      if ((destinationFilled || searchTagPresented == "true" || isHotelPlansSearch == "true") && requiredFilled && adult > 0) {
        searchButton.removeAttribute("disabled");
        searchButton.classList.remove("c-button__link--disabled");
      } else {
        searchButton.setAttribute("disabled", "disabled");
        searchButton.classList.add("c-button__link--disabled");
      }
    }
  }

  function resultRefinesToggle() {
    const refinesList = document.querySelector(".js-search-result-refines-list");
    const toggle = document.querySelector(".js-seartch-result-refines-toggle");

    if (refinesList) {
      const refinesListHeight = refinesList.clientHeight;
      if (refinesListHeight >= 48) {
        toggle.style.display = "flex";
      }
    }
  }

  resultRefinesToggle();
});
