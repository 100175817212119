import Balloon from "./balloon";
import BalloonAds from "./balloon-ads";
import JoinHeader from "./join-header";

document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelector("#js-presses.presses-show")) {
    return;
  }
  JoinHeader();

  const balloon = Balloon();
  if (balloon) {
    const options = { rootMargin: "-70% 0px 200px" };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        balloon.toggle(!entry.isIntersecting);
      });
    }, options);
    observer.observe(document.querySelector(".js-floating-viewport"));
  }

  const balloonAds = BalloonAds();
  if (balloonAds) {
    const options = { rootMargin: "-100% 0%" };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        balloonAds.toggle(!entry.isIntersecting);
      });
    }, options);
    observer.observe(document.querySelector(".js-floating-viewport"));
  }
});
